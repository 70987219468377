import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import './stylesheets/styles.css';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { useDispatch } from './store';
import { loadAccounts, loadDists, loadGroups, setAccount, setDist, setGroup } from './slices/userprofile';
import { useEffect } from 'react';
import React from 'react';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  const dispatch = useDispatch();
  
  React.useEffect(()=>{
    if(auth.isAuthenticated){
      dispatch(setDist(auth.user.distID));
      dispatch(setAccount(auth.user.accountID));
      dispatch(setGroup(auth.user.groupID));
      dispatch(loadDists());
      console.log("Loading User Profile...");
      if(auth.user.distID!==""){
        dispatch(loadAccounts(auth.user.distID));
      }
      if (auth.user.distID!=="" && auth.user.accountID !== "") {
        dispatch(loadGroups(auth.user.distID, auth.user.accountID));
      }

    }
  },[auth.isAuthenticated]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
