import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import {
  Avatar,
  Box,
  IconButton,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { useDispatch, useSelector } from 'src/store';
import * as React from 'react';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';
import { t } from 'i18next';
import { loadAccounts, loadGroups, setAccount, setDist, setGroup } from 'src/slices/userprofile';

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    display: inline-flex;
    border-radius: ${theme.general.borderRadiusLg};
    height: 58px;
    justify-content: center;
    font-size: ${theme.typography.pxToRem(13)};
    position: relative;
    margin-left: ${theme.spacing(1)};

    .MuiSvgIcon-root {
      transition: ${theme.transitions.create(['color'])};
      font-size: ${theme.typography.pxToRem(16)};
      color: ${theme.colors.alpha.trueWhite[50]};
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.alpha.black[10]};

      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  
  return color;
}

function stringAvatar(name: string) {
  if (name == null || name === "") {
    name = 'UNDEF';
  }
  name = name.trim();
  if (name.indexOf(" ") > -1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  } else {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0]}`,
    };
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  listItems: any[];
  onClose: (value: string) => void;
}

function DistDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open, listItems } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };
  
  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('Select Distribuitor Account')}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {listItems.map((key) => (
          <ListItem button onClick={() => handleListItemClick(key.distID)} key={key.distID}>
            <ListItemAvatar>
              <Avatar variant="rounded" alt={key.description} {...stringAvatar(key.description)} />
                
            </ListItemAvatar>
            <ListItemText primary={key.description} />
          </ListItem>
        ))}
       
      </List>
    </Dialog>
  );
}

function AccountDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open, listItems } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('Select Account/Company')}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {listItems.map((key) => (
          <ListItem button onClick={() => handleListItemClick(key.accountID)} key={key.accountID}>
            <ListItemAvatar>
              <Avatar variant="rounded" alt={key.description} {...stringAvatar(key.description)} />

            </ListItemAvatar>
            <ListItemText primary={key.description} />
          </ListItem>
        ))}

      </List>
    </Dialog>
  );
}

function GroupDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open, listItems } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('Select Group/Fleet')}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {listItems.map((key) => (
          <ListItem button onClick={() => handleListItemClick(key.groupID)} key={key.groupID}>
            <ListItemAvatar>
              <Avatar variant="rounded" alt={key.description} {...stringAvatar(key.description)} />

            </ListItemAvatar>
            <ListItemText primary={key.description} />
          </ListItem>
        ))}

      </List>
    </Dialog>
  );
}

function Userbox() {
  const { t }: { t: any } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userprofile = useSelector((state)=>state.userProfile);
  const [selDist, setSelDist] = React.useState(userprofile.currentDist.distID);
  const [selAccount, setSelAccount] = React.useState(userprofile.currentAccount.accountID);
  const [selGroup, setSelGroup] = React.useState(userprofile.currentGroup.groupID);

  const { user, logout } = useAuth();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openDistSel, setOpenDistSel] = React.useState(false);
  const [openAccountSel, setOpenAccountSel] = React.useState(false);
  const [openGroupSel, setOpenGroupSel] = React.useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpenDistSelection = () => {
    setOpenDistSel(true);
  };

  const handleCloseDistSelection = (value: string) => {
    setOpenDistSel(false);
    setSelDist(value);
    dispatch(setDist(value));
    dispatch(loadAccounts(value));
  };

  const handleOpenAccountSelection = () => {
    setOpenAccountSel(true);
  };

  const handleCloseAccountSelection = (value: string) => {
    setOpenAccountSel(false);
    setSelAccount(value);
    dispatch(setAccount(value));
    dispatch(loadGroups(selDist, value));
  };

  const handleOpenGroupSelection = () => {
    setOpenGroupSel(true);
  };

  const handleCloseGroupSelection = (value: string) => {
    setOpenGroupSel(false);
    setSelGroup(value);
    dispatch(setGroup(value));
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.contactName} {...stringAvatar(user.contactName)} />
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1
            }}
          />
        </Box>
      </IconButtonPrimary>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.contactName} {...stringAvatar(user.contactName)} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.contactName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.role}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
          >
          {user.role==="admin" ?
            <ListItem
            button
            onClick={handleOpenDistSelection}
            >
              {/*<AccountBoxTwoToneIcon fontSize="small" />*/}
              <Avatar variant="rounded" {...stringAvatar(userprofile.currentDist.description)} />
              <ListItemText primary={userprofile.currentDist.description} />
            </ListItem>
            : null
          }

          {(user.role === "admin" || user.role==="distManager") && userprofile.currentDist.distID!=="" ?
            <ListItem
              button
              onClick={handleOpenAccountSelection}
            >
              {/*<AccountBoxTwoToneIcon fontSize="small" />*/}
              <Avatar variant="rounded" {...stringAvatar(userprofile.currentAccount.description)} />
              <ListItemText primary={userprofile.currentAccount.description} />
            </ListItem>
            : null
          }

          {userprofile.currentDist.distID !== "" && userprofile.currentAccount.accountID !== "" ?
            <ListItem
              button
              onClick={handleOpenGroupSelection}
            >
              {/*<AccountBoxTwoToneIcon fontSize="small" />*/}
              <Avatar variant="rounded" {...stringAvatar(userprofile.currentGroup.description)} />
              <ListItemText primary={userprofile.currentGroup.description} />
            </ListItem>
            : null
          }

          
       
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
      <DistDialog
        selectedValue={selDist}
        open={openDistSel}
        onClose={handleCloseDistSelection}
        listItems={userprofile.dist}
      />
      <AccountDialog
        selectedValue={selAccount}
        open={openAccountSel}
        onClose={handleCloseAccountSelection}
        listItems={userprofile.account}
      />
      <GroupDialog
        selectedValue={selGroup}
        open={openGroupSel}
        onClose={handleCloseGroupSelection}
        listItems={userprofile.group}
      />
    </>
  );
}

export default Userbox;
