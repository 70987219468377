import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const CurrentLocation = Loader(
  lazy(() => import('src/content/pages/Map/index'))
);
const Automation = Loader(
  lazy(() => import('src/content/dashboards/Automation'))
);


const MapRoutes = [
  {
    path: '',
    element: <CurrentLocation />
  },
];

export default MapRoutes;
