/*
user: {
                id: user.uid,
                jobtitle: 'Lead Developer',
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email,
                
                location: 'San Francisco, USA',
                username: 'admin',
                posts: '4',
                coverImg: '/static/images/placeholders/covers/1.jpg',
                followers: '5684',
                description:
                  'Lorem ipsum is placeholder text commonly used in the graphic.'
              }
*/



import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { User } from 'src/models/user';
import axios from 'src/utils/axios';
import { verify, JWT_SECRET } from 'src/utils/jwt';
import PropTypes from 'prop-types';
import { setAccount, setDist, setGroup } from 'src/slices/userprofile';
import { useDispatch } from 'src/store';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JaiblanAuth';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: any | null): void => {
  /*if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }*/
  localStorage.setItem('session', accessToken);
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JaiblanAuth',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const response = await axios.get<{ loggedIn: boolean, session: any, userData: User }>(
          '/api/login'
        );
        const { loggedIn, session, userData } = response.data;
        
        if (loggedIn) {
          console.log(userData);
          userData.role = (typeof userData.isDist !== "undefined" && userData.isDist) ? 'admin' : ((typeof userData.isDistManager !== "undefined" && userData.isDistManager) ? 'distManager' : ((typeof userData.isAccountManager !== "undefined" && userData.isAccountManager) ? 'accountManager' : 'customer'));
          setSession(accessToken);
          dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: userData,
              }
            });
          
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    console.log("Trying to login");
    const response = await axios.post<{ session: any, userData: User, loggedIn: boolean}>(
      '/api/login',
      {
        username: username,
        password: password,
      }
    );
    const { session, userData, loggedIn} = response.data;
    console.log(userData);
    userData.role = (typeof userData.isDist !== "undefined" && userData.isDist) ? 'admin' : ((typeof userData.isDistManager !== "undefined" && userData.isDistManager) ? 'distManager' : ((typeof userData.isAccountManager !== "undefined" && userData.isAccountManager) ? 'accountManager' : 'customer'));

    if(loggedIn){
      setSession(session);

      dispatch({
        type: 'LOGIN',
        payload: {
          user: userData
        }
      });
    } else {
      setSession(session);
      dispatch({
        type: 'LOGOUT',
      });
    }
    
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    const response = await axios.get<{ }>(
      '/api/logout',
    );
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: User }>(
      '/api/account/register',
      {
        email,
        name,
        password
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JaiblanAuth',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
