import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';
import _ from 'lodash';
import type {
  AppThunk
} from 'src/store';
import axios from 'src/utils/axios';
import moment from 'moment';

interface DistID {
  distID: string;
  description: string;
}

interface AccountID {
  accountID: string;
  description: string;
}

interface GroupID {
  groupID: string;
  description: string;
}

interface UserProfile {
  dist: DistID[];
  account: AccountID[];
  group: GroupID[];
  currentDist: DistID;
  currentAccount: AccountID;
  currentGroup: GroupID;
  startingDate: number;
  finishingDate: number;
}

const initialState: UserProfile = {
  dist: [],
  account: [],
  group: [],
  currentDist: {
    distID: null,
    description: "Todos"
  },
  currentAccount: {
    accountID: null,
    description: "Todos"
  },
  currentGroup: {
    groupID: null,
    description: "Todos"
  },
  startingDate: moment().startOf('day').unix(),
  finishingDate: moment().endOf('day').unix()
};

const slice = createSlice({
  name: 'userprofile',
  initialState,
  reducers: {
    loadDists(
      state: UserProfile,
      action: PayloadAction < {
        data: any[]
      } >
    ) {
      const data = action.payload;
      var distData: DistID[] = [];
      for (var key in data) {
        var _tmp = data[key];
        var _tmp2: DistID = {
          distID: _tmp.id,
          description: _tmp.label
        }
        distData.push(_tmp2);
        if (_tmp2.distID === state.currentDist.distID) {
          state.currentDist = _tmp2;
        }
      }
      state.dist = distData;
    },
    loadAccounts(
      state: UserProfile,
      action: PayloadAction < {
        data: any[]
      } >
    ) {
      const data = action.payload;
      var distData: AccountID[] = [];;
      for (var key in data) {
        var _tmp = data[key];
        var _tmp2: AccountID = {
          accountID: _tmp.id,
          description: _tmp.label
        }
        distData.push(_tmp2);
        if (_tmp2.accountID === state.currentAccount.accountID) {
          state.currentAccount = _tmp2;
        }
      }
      state.account = distData;
    },
    loadGroups(
      state: UserProfile,
      action: PayloadAction < {
        data: any[]
      } >
    ) {
      const data = action.payload;
      var distData: GroupID[] = [];
      for (var key in data) {
        var _tmp = data[key];
        var _tmp2: GroupID = {
          groupID: _tmp.id,
          description: _tmp.label
        }
        distData.push(_tmp2);
        if (_tmp2.groupID === state.currentGroup.groupID) {
          state.currentGroup = _tmp2;
        }
      }
      state.group = distData;
    },
    setDist(state: UserProfile, action: PayloadAction < {
      distID: string
    } > ) {
      var {
        distID
      } = action.payload;
      state.currentDist.distID = distID;
      for (var dist of state.dist) {
        if (dist.distID === distID) {
          state.currentDist = dist;
        }
      }

    },
    setAccount(state: UserProfile, action: PayloadAction < {
      accountID: string
    } > ) {
      var {
        accountID
      } = action.payload;
      state.currentAccount.accountID = accountID;
      for (var account of state.account) {
        if (account.accountID === accountID) {
          state.currentAccount = account;
        }
      }
    },
    setGroup(state: UserProfile, action: PayloadAction < {
      groupID: string
    } > ) {
      var {
        groupID
      } = action.payload;
      state.currentGroup.groupID = groupID;
      for (var group of state.group) {
        if (group.groupID === groupID) {
          state.currentGroup = group;
        }
      }
    },
    setStartingDate(state: UserProfile, action: PayloadAction < {
      stDate: number
    } > ) {
      var {
        stDate
      } = action.payload;
      state.startingDate = stDate;
    },
    setFinishingDate(state: UserProfile, action: PayloadAction < {
      endDate: number
    } > ) {
      var {
        endDate
      } = action.payload;
      state.finishingDate = endDate;
    },

  }
});

export const reducer = slice.reducer;

export const loadDists =
  (): AppThunk =>
  async (dispatch): Promise < void > => {
    var url = `/dist/r/dists/`;

    const response = await axios.get < {
      data: any
    } > (
      url,
    );
    dispatch(slice.actions.loadDists(response.data));
  };

export const loadAccounts =
  (distID: string): AppThunk =>
  async (dispatch): Promise < void > => {
    var url = `/dist/r/accounts/${distID}`;
    const response = await axios.get < {
      data: any
    } > (
      url,
    );
    dispatch(slice.actions.loadAccounts(response.data));
  };
export const loadGroups =
  (distID: string, accountID: string): AppThunk =>
  async (dispatch): Promise < void > => {
    var url = `/group/r/${distID}/${accountID}/`;
    const response = await axios.get < {
      data: any
    } > (
      url,
    );
    dispatch(slice.actions.loadGroups(response.data));
  };

export const setDist =
  (distID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setDist({
      distID: distID
    }));
  };

export const setAccount =
  (accountID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setAccount({
      accountID: accountID
    }));
  };

export const setGroup =
  (groupID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setGroup({
      groupID: groupID
    }));
  };

export const setStartingDate =
  (startingDate: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setStartingDate({
      stDate: startingDate
    }));
  };
export const setFinishingDate =
  (finishingDate: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setFinishingDate({
      endDate: finishingDate
    }));
  };




export default slice;