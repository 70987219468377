import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as currentLocationReducer } from 'src/slices/currentLocation';
import { reducer as userprofileReducer} from 'src/slices/userprofile';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  currentLocation: currentLocationReducer,
  userProfile: userprofileReducer
});

export default rootReducer;
