import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';
import _ from 'lodash';
import type {
  AppThunk
} from 'src/store';
import axios from 'src/utils/axios';
import type {
  Event
} from 'src/models/calendar';
import {
  boatSensorStatistics,
  Device,
  deviceHistoric,
  devicestatus,
  ICurrentLocationTable,
  IGeopoint
} from 'src/models/device';
import moment from 'moment';
import {
  string
} from 'prop-types';
import { Sensor } from 'src/models/sensor';

interface CurrentLocation {
  showVehicles: boolean,
    openDrawer: boolean,
    loading: boolean,
    latestDeviceData: Device[],
    currentLocationTable: ICurrentLocationTable[],
    showOnlyActiveDevices: boolean,
    rawLatestData: any,
    selectedDevice: string,
    showHistoricMap: boolean,
    selectedSensorID: string,
    selectedSensor: Sensor,
    deviceHistory: deviceHistoric[],
    historicMapLayer: any,
    flyTo: [number, number],
    searchedDevice: string,
    statusFilter: devicestatus
}

const initialState: CurrentLocation = {
  showVehicles: false,
  openDrawer: false,
  loading: false,
  latestDeviceData: [],
  currentLocationTable: [],
  showOnlyActiveDevices: false,
  rawLatestData: {},
  selectedDevice: "",
  showHistoricMap: false,
  selectedSensorID: "",
  selectedSensor: undefined,
  deviceHistory: [],
  historicMapLayer: undefined,
  flyTo: [0, 0],
  searchedDevice: '',
  statusFilter: null
};

const slice = createSlice({
  name: 'currentLocation',
  initialState,
  reducers: {
    loadDevices(
      state: CurrentLocation,
      action: PayloadAction < {
        data: any[]
      } >
    ) {
      const packet = action.payload;
      var dvData = [];
      var tableData = [];
      for (let key in packet) {
        let _devData = packet[key];
        var gp: IGeopoint = {
          latitude: _devData.lastValidLatitude,
          longitude: _devData.lastValidLongitude
        }
        var boatRawData;
        var rawData;
        var boatSensorStatistics;
        var parsedStatistics = [];
        try {
          rawData = JSON.parse(_devData.rawData)
        } catch (e) {
          rawData = "{}";
        }

        try {
          boatRawData = JSON.parse(_devData.boatRawData)
        } catch (e) {
          boatRawData = "{}";
        }
        if (rawData == null) {
          rawData = {};
        }
        if (boatRawData == null) {
          boatRawData = {};
        }

        try {
          boatSensorStatistics = JSON.parse(_devData.boatSensorStatistic)
        } catch (e) {
          boatSensorStatistics = "{}";
        }

        if (boatSensorStatistics == null) {
          boatSensorStatistics = {};
        }
        if (Object.keys(boatSensorStatistics).length > 0) {
          for (var stat in boatSensorStatistics) {
            var tmp: boatSensorStatistics = {
              description: stat,
              value: boatSensorStatistics[stat].v,
              timestamp: boatSensorStatistics[stat].d
            }
            parsedStatistics.push(tmp);
          }
        }
        if ((state.showOnlyActiveDevices && _devData.isActive == 1) || !state.showOnlyActiveDevices) {
          let _tmp: Device = {
            deviceID: _devData.deviceID,
            displayName: _devData.displayName,
            description: _devData.description,
            imeiNumber: _devData.uniqueID,
            location: gp,
            speedKPH: _devData.lastValidSpeedKPH,
            speedKnots: parseInt(_devData.lastValidSpeedKPH) * 0.53995680,
            lastTransmissionTime: _devData.lastGPSTimestamp,
            isBoat: (_devData.isBoat) ? true : false,
            isMotorOn: (_devData.ignEstado == 1) ? true : false,
            isMotorTrackingOn: (_devData.ignOption == 1) ? true : false,
            isActive: (_devData.isActive == 1) ? true : false,
            isOnline: false,
            pushpinID: _devData.pushpinID,
            licensePlate: _devData.licensePlate,
            statusCode: _devData.lastValidStatusCode,
            address: _devData.address,
            enableDriver: _devData.enableDriver == 1 ? true : false,
            driverID: _devData.driverID,
            heading: _devData.lastValidHeading,
            odometerKM: _devData.lastOdometerKM,
            deviceType: _devData.deviceType,
            isIgnitionDisabled: _devData.isIgnitionDisabled == 1 ? true : false,
            disableIgnition: _devData.disableIgnition == 1 ? true : false,
            boatData: {
              countryFlag: _devData.countryFlag,
              imoNumber: _devData.imoNumber,
              boatRadioLetter: _devData.boatRadioLetter,
              boatRawData: boatRawData,
              rawData: rawData,
              photoURL1: _devData.photoURL1,
              photoURL2: _devData.photoURL2,
              photoURL3: _devData.photoURL3,
              MMSI: _devData.MMSI,
              typeOfBoat: (_devData.boatType != null && _devData.boatType !== "null") ? _devData.boatType : "N/A",
              statistics: parsedStatistics
            },
            id: _devData.id,
            timezone: _devData.timezone,
            uniqueID: _devData.uniqueID,
            lastStartTime: _devData.lastStartTime,
            lastStopTime: _devData.lastStopTime,
            panic: (typeof rawData !== "undefined" && typeof rawData.panic !== "undefined" && rawData.panic == 1) ? true : false,
            typeOfDevice: 'avl',
            devicestatus: 'disabled',
            motorStatus: 'motorOn',
            phoneNumber: _devData.simPhoneNumber,
            networkProvider: _devData.networkProvider,
            batteryLevel: _devData.lastBatteryLevel,
            engineLoad: _devData.lastEngineLoad,
            waterTemperature: _devData.lastWaterTemperature
          }
          if (_tmp.isMotorTrackingOn) {
            _tmp.motorStatus = (_tmp.isMotorOn) ? "motorOn" : "motorOff";
          } else {
            _tmp.motorStatus = "motorTrackingDisabled";
          }

          const currentTime = moment().unix();
          var devicestatus: devicestatus = "reportingOK";

          if (currentTime - _tmp.lastTransmissionTime > 7200) {
            devicestatus = "notReporting";
          }
          if (!_tmp.isActive) {
            devicestatus = "disabled";
          }
          if (_tmp.panic) {
            devicestatus = "emergency";
          }
          _tmp.devicestatus = devicestatus;
          if (_tmp.isBoat) {
            _tmp.typeOfDevice = "satellital_beacon";
          }
          let _tmp2: ICurrentLocationTable = {
            deviceID: _devData.deviceID,
            displayName: _devData.displayName,
            description: _devData.description,
            speedKPH: parseInt(_devData.lastValidSpeedKPH),
            speedKnots: parseInt(_devData.lastValidSpeedKPH) * 0.53995680,
            lastTransmissionTime: _devData.lastGPSTimestamp,
            isBoat: (_devData.isBoat) ? true : false,
            isMotorOn: (_devData.ignEstado === 1) ? true : false,
            isMotorTrackingOn: (_devData.ignOption == 1) ? true : false,
            isActive: (_devData.isActive == 1) ? true : false,
            isOnline: false,
            speed: (_devData.isBoat) ? `${~~(parseInt(_devData.lastValidSpeedKPH) * 0.53995680)} kt` : `${parseInt(_devData.lastValidSpeedKPH)} kph`,
            devicestatus: _tmp.devicestatus,
            motorStatus: _tmp.motorStatus,
            location: gp
          }
          tableData.push(_tmp2);
          dvData.push(_tmp);
        }

      }
      state.latestDeviceData = dvData;
      state.loading = false;
      state.currentLocationTable = tableData;
      state.rawLatestData = packet;
    },
    showOnlyActiveDevicesOn(state: CurrentLocation) {
      state.showOnlyActiveDevices = true;
    },
    showOnlyActiveDevicesOff(state: CurrentLocation) {
      state.showOnlyActiveDevices = false;
    },
    toggleTable(state: CurrentLocation) {
      state.openDrawer = !state.openDrawer;
    },
    setSelectedDevice(state: CurrentLocation, action: PayloadAction < {
      deviceID: string;
    } > ) {
      const {
        deviceID
      } = action.payload;
      state.selectedDevice = deviceID;
    },
    setSelectedSensor(state: CurrentLocation, action: PayloadAction<{
      sensorID: string;
      sensor: Sensor;
    }>) {
      const {
        sensorID,
        sensor
      } = action.payload;
      state.selectedSensorID = sensorID;
      state.selectedSensor = sensor;
    },
    clearSelectedDevice(state: CurrentLocation) {
      state.selectedDevice = "";
    },
    showHistoricMap(state: CurrentLocation, action: PayloadAction < {
      deviceID: string;
    } > ) {
      const {
        deviceID
      } = action.payload;
      state.showHistoricMap = true;
      state.selectedDevice = deviceID;
    },
    hideHistoricMap(state: CurrentLocation, action: PayloadAction < {
      deviceID: string;
    } > ) {
      const {
        deviceID
      } = action.payload;
      state.showHistoricMap = false;
      state.selectedDevice = deviceID;
    },
    setHistoricDeviceData(state: CurrentLocation, action: PayloadAction<{
      historicData: deviceHistoric[];
    }>) {
      const {
        historicData
      } = action.payload;
      state.deviceHistory = historicData;
    },
    setHistoricMapLayer(state: CurrentLocation, action: PayloadAction<{
      layer:any;
    }>) {
      const {
       layer
      } = action.payload;
      console.log("Setting map layer...");
      console.log(layer);
      state.historicMapLayer = layer;
    },
    setFlyTo(state: CurrentLocation, action: PayloadAction<{
    latitude: number;
    longitude:number;
    }>) {
      const {
        latitude,
        longitude
      } = action.payload;
      state.flyTo = [latitude,longitude];
    },
    setSearchedDevice(state: CurrentLocation, action: PayloadAction<{
      searchedDevice: string;
    }>) {
      const {
        searchedDevice
      } = action.payload;
      state.searchedDevice = searchedDevice;
    },
    setStatusFilter(state: CurrentLocation, action: PayloadAction<{
      selectedStatus: devicestatus;
    }>) {
      const {
        selectedStatus
      } = action.payload;
      state.statusFilter = selectedStatus;
    },


  }
});

export const reducer = slice.reducer;

export const loadDevices =
  (distID: string, accountID: string, groupID: string): AppThunk =>
  async (dispatch): Promise < void > => {
    var url = `/currentLocation/data/${distID}/${accountID}/${groupID}`;
    if (typeof groupID !== "undefined" && groupID && typeof accountID !== "undefined" && accountID && typeof distID !== "undefined" && distID) {
      url = `/currentLocation/data/${distID}/${accountID}/${groupID}`;
    } else if (typeof accountID !== "undefined" && accountID && typeof distID !== "undefined" && distID) {
      url = `/currentLocation/data/${distID}/${accountID}`;
    } else if (typeof distID !== "undefined" && distID) {
      url = `/currentLocation/data/${distID}`;
    } else {
      url = `/currentLocation/data`;
    }
    const response = await axios.get < {
      data: any
    } > (
      url,
    );
    dispatch(slice.actions.loadDevices(response.data));
  };

export const showOnlyActiveDevicesOn =
  (): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.showOnlyActiveDevicesOn());
  };
export const showOnlyActiveDevicesOff =
  (): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.showOnlyActiveDevicesOff());
  };
export const toggleTable =
  (): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.toggleTable());
  };
export const setSelectedDevice =
  (deviceID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSelectedDevice({
      deviceID: deviceID
    }));
  };
export const setSelectedSensor =
  (sensorID: string, sensor: Sensor): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setSelectedSensor({
        sensorID: sensorID,
        sensor: sensor
      }));
    };
export const clearSelectedDevice =
  (): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.clearSelectedDevice());
  };
export const showHistoricMap =
  (deviceID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.showHistoricMap({
      deviceID: deviceID
    }));
  };
export const hideHistoricMap =
  (deviceID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.hideHistoricMap({
      deviceID: deviceID
    }));
  };

export const setHistoricDeviceData =
  (historicData: deviceHistoric[]): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setHistoricDeviceData({
        historicData: historicData
      }));
    };

export const setHistoricMapLayer =
  (layer: any): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setHistoricMapLayer({
       layer: layer
      }));
    };

export const setFlyTo =
  (latitude: number, longitude: number): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setFlyTo({
        latitude:latitude,
        longitude: longitude
      }));
    };

export const setSearchedDevice =
  (searchedDevice: string): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setSearchedDevice({
       searchedDevice: searchedDevice
      }));
    };
export const setStatusFilter =
  (statusFilter: devicestatus): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setStatusFilter({
        selectedStatus: statusFilter
      }));
    };







export default slice;